<template>
    <section class="mx-auto flex flex-col gap-8">
        <AppHero class="mx-auto" />
        <article>
            <AppTypography class="text-red-500" tag="h1">
                Expect Low Prices
            </AppTypography>
            <AppTypography>
                Welcome to PriceCo Foods, where we strive to provide
                shoppers with the finest natural and organic foods
                available, while maintaining strict quality standards.
                All this is accomplished with a staff that is
                professional, knowledgable and courteous. Our upscale
                supermarket features unique farmer's market style
                produce, scratch bakery, fine wines, liquor and floral
                departments, along with the meat, seafood and deli
                items.
            </AppTypography>
            <AppTypography
                >It is our goal to give each of our customers a
                pleasant shopping experience everytime they visit.
                This is accomplished by providing not only the usual
                super market products, but unique or signature items
                found only at PriceCo Foods as well. Find us in the
                Junction Shopping Center in Sonora, California, where
                our motto is "Expect low prices".
            </AppTypography>
        </article>
    </section>
</template>
